module.exports = {

    dropzoneOptionsUniverse(baseURL) {
        return {
            url: baseURL + `/arvea-universe/`,
            thumbnailWidth: 150,
            thumbnailHeight: 150,
            maxFilesize: 8,
            autoProcessQueue: false,
            uploadMultiple: false,
            addRemoveLinks: true,
            dictDefaultMessage: "Click or drag and drop to upload file",
            dictRemoveFile: "x",
            withCredentials: true,

            accept: function (file, done) {
                console.log(file);
                if (file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    done("Error! Files of this type are not accepted");
                }
                else { done(); }
            },

        };
    },
    dropzoneOptionsPortfolio(baseURL) {
        return {
            url: baseURL + `/portfolio/excel-upload/`,
            thumbnailWidth: 150,
            thumbnailHeight: 150,
            maxFilesize: 8,
            autoProcessQueue: false,
            uploadMultiple: false,
            addRemoveLinks: true,
            dictDefaultMessage: "Click or drag and drop to upload file",
            dictRemoveFile: "x",
            withCredentials: true,

            accept: function (file, done) {
                console.log(file);
                if (file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    done("Error! Files of this type are not accepted");
                }
                else { done(); }
            },

        };
    },

    dataDropzoneOptionsPortfolio(baseURL) {
        return {
            url: baseURL + `/portfolio/data-excel-upload/`,
            thumbnailWidth: 150,
            thumbnailHeight: 150,
            maxFilesize: 8,
            autoProcessQueue: false,
            uploadMultiple: false,
            addRemoveLinks: true,
            dictDefaultMessage: "Click or drag and drop to upload file",
            dictRemoveFile: "x",
            withCredentials: true,

            accept: function (file, done) {
                console.log(file);
                if (file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    done("Error! Files of this type are not accepted");
                }
                else { done(); }
            },

        };
    }

};